import { getComponentsExpanded } from "../../../services/component.service.mjs"


export default function setupBlocks(editor) {

  getComponentsExpanded((resp) => {
    Object.entries(resp.data).map(([componentName, componentHtml]) => {
      // console.log(componentName)
      const id = componentName.toLowerCase()
      editor.BlockManager.add(id, {
        category: 'Components',
        label: componentName,
        attributes: { class: 'gjs-fonts gjs-f-b1' },
        content: componentHtml
      })
    })
  }, (err) => {
    console.log(err)
  })

}