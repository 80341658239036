import { KeyboardArrowRight } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import { Button, ListItem, ListItemButton, ListItemContent, ListItemDecorator } from "@mui/joy";

export const AddButton = ({label = "Criar",action = ()=>{}}) => <Button onClick={action} startDecorator={<Add />}>{label}</Button>

export const MenuButton = ({label = "Sem Nome", action = () => {}, selected}) => <ListItem
sx={{
  my:2,
  background: selected? 'rgb(97,218,251)' : '#FFF',
  background: selected?'linear-gradient(90deg, rgba(97,218,251,1) 0%, rgba(78,165,197,1) 100%)': '#FFF',
  border:"1px solid #D6D5E4",
  borderRadius: "10px",
}}
>
  <ListItemButton sx={{
    
    p:1,}}
    onClick={action}>
    <ListItemDecorator>
      {/* <Sheet
        variant="solid"
        color="primary"
      >
        <Add />
      </Sheet> */}
    </ListItemDecorator>
    <ListItemContent sx={{
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '138.69%',
      display: 'flex',
      alignItems: 'center',
      color: selected? '#FFF' : '#2B404E'}}>{label}</ListItemContent>
    <KeyboardArrowRight fontSize="xl3" sx={{
      p:1, color: 'rgba(97, 218, 251, 1)',
      border: "1px solid rgba(97, 218, 251, 0.4)", borderRadius: "100%", bgcolor:"rgba(97, 218, 251, 0.27)"}} />
  </ListItemButton>
</ListItem>;