import axios from 'axios';
import grapesjs from 'grapesjs';
import { BUILDER_TYPE } from '../../../constants/project-types.const.mjs';
import assetManagerConfig from './asset-manager.config.mjs';
import setupBlocks from './blocks.config.mjs';
import setupFonts from './fonts.config.mjs';
import setupPanels from './panels.config.mjs';
import setupTOCBlock from './toc-block.config.mjs';

export default function initEditor(divId, content, projectName, builderType) {

  let uploadUrl = axios.defaults.baseURL

  switch (builderType) {
    case BUILDER_TYPE.PROJECT:
      uploadUrl += '/projects/'
      break
    case BUILDER_TYPE.TEMPLATE:
      uploadUrl += '/templates/'
      break
    case BUILDER_TYPE.COMPONENT:
      uploadUrl += '/components/'
      break
  }
  uploadUrl += projectName + '/images'

  // console.log('>>>>>>', uploadUrl)

  const elId = '#' + divId

  var editor = grapesjs.init({
    container: elId,
    components: content,
    storageManager: { autoload: 0 },
    plugins: ['gjs-blocks-basic'],
    pluginsOpts: {
      'gjs-blocks-basic': {
        /* ...options */
      }
    },
    showDevices: false,
    assetManager: {
      // embedAsBase64: false,
      multiUpload: false,
      upload: uploadUrl,
      uploadName: 'file'
    },
    height: '100%',
    canvas: {
      styles: [
        'https://fonts.googleapis.com/css?family=Roboto',
        'https://fonts.googleapis.com/css?family=Raleway', 
        'https://fonts.googleapis.com/css?family=Open+Sans',
      ]
    }
  })

  setupFonts(editor)
  assetManagerConfig(editor, projectName, builderType)
  setupPanels(editor, projectName, builderType)
  setupBlocks(editor)
  setupTOCBlock(editor)

  return editor

}