import axios from "axios"

const baseUrl = '/projects'

export function createProject(projectName, templateName, onSuccess, onError) {

    axios.post(baseUrl, {name: projectName, template: templateName})
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function getProjects(onSuccess, onError) {

    axios.get(baseUrl)
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function getProject(projectName, onSuccess, onError) {

    axios.get(baseUrl+'/'+projectName)
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function deleteImage(imgSrc, onSuccess, onError) {

    axios.delete(imgSrc)
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function getImages(projectName, onSuccess, onError) {

    axios.get(baseUrl+'/'+projectName+'/images')
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

// export function exportToPdf(projectName, onSuccess, onError) {

//     axios.get(baseUrl+'/'+projectName+'/pdf')
//     .then(response => onSuccess(response.data))
//     .catch(error => onError(error.response.data))
    
// }

export function updateProject(projectName, content, onSuccess, onError) {

    axios.put(baseUrl+'/'+projectName, {content: content})
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function deleteProject(documentId, onSuccess, onError) {
    axios
      .delete(baseUrl + '/' + documentId)
      .then((response) => onSuccess(response.data))
      .catch((error) => onError(error.response.data));

}