import 'grapesjs-blocks-basic/dist/grapesjs-blocks-basic.min.js';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs/dist/grapes.min.js';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { BUILDER_TYPE } from '../../constants/project-types.const.mjs';
import { getProject } from '../../services/project.service.mjs';
import { getTemplate } from '../../services/template.service.mjs';
import { getComponent } from '../../services/component.service.mjs';
import './Builder.css';
import initEditor from './config/editor.config.mjs';

export default function Builder() {

  const [content, setContent] = React.useState('<p>Loading content ...</p>')
  const [params] = useSearchParams()
  const allowedTypes = [BUILDER_TYPE.PROJECT, BUILDER_TYPE.TEMPLATE, BUILDER_TYPE.COMPONENT]

  React.useEffect(() => {
    var selectedType = null
    var typeValue = null

    allowedTypes.map((type) => {
      if (params.has(type)) {
        selectedType = type
        typeValue = params.get(type)
      }
    })

    let fetchContentService = null

    switch (selectedType) {
      case BUILDER_TYPE.PROJECT:
        fetchContentService = getProject
        break
      case BUILDER_TYPE.TEMPLATE:
        fetchContentService = getTemplate
        break
      case BUILDER_TYPE.COMPONENT:
        fetchContentService = getComponent
        break
    }

    fetchContentService(typeValue, (resp) => {
      // console.log(resp.data)
      setContent(resp.data)
    }, (err) => { })

    initEditor('gjs', content, typeValue, selectedType)

  });

  return (
    <div id="gjs"></div>
  );
}
