import axios from "axios"

const baseUrl = '/templates'

export function createTemplate(templateName, sampleTemplateName, onSuccess, onError) {

    axios.post(baseUrl, {name: templateName, template: sampleTemplateName})
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function getTemplates(onSuccess, onError) {

    axios.get(baseUrl)
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function getTemplate(templateName, onSuccess, onError) {

    axios.get(baseUrl+'/'+templateName)
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function deleteImage(imgSrc, onSuccess, onError) {

    axios.delete(imgSrc)
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function getImages(templateName, onSuccess, onError) {

    axios.get(baseUrl+'/'+templateName+'/images')
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

// export function exportToPdf(templateName, onSuccess, onError) {

//     axios.get(baseUrl+'/'+templateName+'/pdf')
//     .then(response => onSuccess(response.data))
//     .catch(error => onError(error.response.data))
    
// }

export function updateTemplate(templateName, content, onSuccess, onError) {

    axios.put(baseUrl+'/'+templateName, {content: content})
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function deleteTemplate(documentId, onSuccess, onError) {
    axios
      .delete(baseUrl + '/' + documentId)
      .then((response) => onSuccess(response.data))
      .catch((error) => onError(error.response.data));

}