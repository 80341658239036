import { Search } from "@mui/icons-material"
import { FormControl, FormLabel, Input } from "@mui/joy"

export const SearchField = ({set,value}) => {
    return <FormControl>
    <FormLabel>
        Pesquisar
    </FormLabel>
    <Input
    startDecorator={<Search />}
    value={value}
    onChange={field => set(field.target.value)} color="secondary" />
    
    </FormControl>
}