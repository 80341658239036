

export default function setupFonts(editor) {

  const prop = editor.StyleManager.getProperty('typography', 'font-family');
  prop.set('options', [
    { value: 'Open Sans', name: 'Open Sans' },
    { value: 'Raleway', name: 'Raleway' },
    { value: 'Roboto', name: 'Roboto' },
  ])
}