import { Grid } from "@mui/material";

export default function Homeland () {
    return <Grid container
    direction="column"
    justifyContent="center"
    alignItems="center" xs={12} sx={{ overflow: 'auto',
    borderRadius: 'sm', height: '100vh',background: '#FFF'}}>
        <Grid item>
        <img src="/working_guy.svg" style={{}}/>
        </Grid>
    </Grid>
}