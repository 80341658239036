import Add from '@mui/icons-material/Add';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import Edit from '@mui/icons-material/Edit';
import List from '@mui/joy/List';
import ListDivider from '@mui/joy/ListDivider';
import Sheet, { sheetClasses } from '@mui/joy/Sheet';
import { Grid, useTheme, Box} from '@mui/material';
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import CreateComponent from './component/CreateComponent.js';
import EditComponent from './component/EditComponent.js';
import EditReport from './report/EditReport.js';
import './Home.css';
import CreateReport from './report/CreateReport.js';
import CreateTemplate from './template/CreateTemplate.js';
import EditTemplate from './template/EditTemplate.js';
import Welcome from './Welcome.js';
import { useKeycloak } from '@react-keycloak/web';
import { MenuButton } from '../../components/Buttons.js';
import Homeland from './Homeland.js';



export default function Home() {

  const navigate = useNavigate();
  const theme = useTheme()
  const goToBuilder = React.useCallback(() => navigate('/builder'), [navigate]);
  const { keycloak } = useKeycloak();


  const [createReportSelected, setCreateReportSelected] = React.useState(false)
  const [editReportSelected, setEditReportSelected] = React.useState(false)
  const [createTemplateSelected, setCreateTemplateSelected] = React.useState(false)
  const [editTemplateSelected, setEditTemplateSelected] = React.useState(false)
  const [createComponentSelected, setCreateComponentSelected] = React.useState(false)
  const [editComponentSelected, setEditComponentSelected] = React.useState(false)


  const MENU_CREATE_REPORT = 0
  const MENU_EDIT_REPORT = 1
  const MENU_CREATE_TEMPLATE = 2
  const MENU_EDIT_TEMPLATE = 3
  const MENU_CREATE_COMPONENT = 4
  const MENU_EDIT_COMPONENT = 5

  const MENU_BUILD_VIEW_HELPER = [
    // {
    //   class: 'fa fa-plus',
    //   label: 'Criar relatório',
    //   value: MENU_CREATE_REPORT
    // },
    {
      class: 'fa fa-plus',
      label: 'Relatório',
      value: MENU_EDIT_REPORT
    },
    // {
    //   class: 'fa fa-plus',
    //   label: 'Criar template',
    //   value: MENU_CREATE_TEMPLATE
    // },
    {
      class: 'fa fa-plus',
      label: 'Template',
      value: MENU_EDIT_TEMPLATE
    },
    // {
    //   class: 'fa fa-plus',
    //   label: 'Criar componente',
    //   value: MENU_CREATE_COMPONENT
    // },
    {
      class: 'fa fa-plus',
      label: 'Componente',
      value: MENU_EDIT_COMPONENT
    },
  ]

  const selectedMenu = MENU_OPTION => {
    setCreateReportSelected(false)
    setEditReportSelected(false)
    setCreateTemplateSelected(false)
    setEditTemplateSelected(false)
    setCreateComponentSelected(false)
    setEditComponentSelected(false)
    switch (MENU_OPTION) {
      case MENU_CREATE_REPORT:
        setCreateReportSelected(true)
        break
      case MENU_EDIT_REPORT:
        setEditReportSelected(true)
        break
      case MENU_CREATE_TEMPLATE:
        setCreateTemplateSelected(true)
        break
      case MENU_EDIT_TEMPLATE:
        setEditTemplateSelected(true)
        break
      case MENU_CREATE_COMPONENT:
        setCreateComponentSelected(true)
        break
      case MENU_EDIT_COMPONENT:
        setEditComponentSelected(true)
        break
    }
  }

  return (
    <Sheet variant="soft" sx={{ width: "100%", borderRadius: 'sm' }} className='root'>

      <Grid container className='menu-panel-container' sx={{ height: '100vh', borderRadius: 4, backgroundColor: 'white', overflow: 'hidden' }}>
        <Grid item container
        direction="column"
        justifyContent="center"
        alignItems="center" sm={5} sx={{ overflow: 'auto',
        borderRadius: 'sm', height: '100vh',background: '#F8F8F8'}}>
          <Grid item sx={{width:"60%"}}>
            <Box 
                >
          <Welcome />
              <List
                aria-label="Network"
                sx={{
                  [`& .${sheetClasses.root}`]: {
                    p: 0.5,
                    lineHeight: 0,
                    borderRadius: 'sm',
                  },
                }}
              >
                {MENU_BUILD_VIEW_HELPER.map((helper, i) =>
                  <div key={i}>
                    
                      <MenuButton action={() => selectedMenu(helper.value)}
                      selected={
                        i+1 ===
                        (editReportSelected||createReportSelected?1:
                        editTemplateSelected||createTemplateSelected?2:
                        editComponentSelected||createComponentSelected?3:null)
                        
                        

                      } label={helper.label} />
                    {/* <ListDivider inset="startContent" /> */}
                  </div>
                )}

                <div>
                    <MenuButton action={() => keycloak.logout()} label="Sair" />
                  <ListDivider inset="startContent" />
                </div>

              </List>
              </Box>
              </Grid>
        </Grid>

        <Grid className='menu-content-panel' item xs={6} sx={{pt:"3%"}} >
          {!createReportSelected && !editReportSelected &&
            !createTemplateSelected && !editTemplateSelected &&
            !createComponentSelected && !editComponentSelected && <Homeland />}

          {createReportSelected && <CreateReport />}
          {editReportSelected && <EditReport sm={() => selectedMenu(MENU_CREATE_REPORT)} />}
          {createTemplateSelected && <CreateTemplate />}
          {editTemplateSelected && <EditTemplate sm={() => selectedMenu(MENU_CREATE_TEMPLATE)} />}
          {createComponentSelected && <CreateComponent />}
          {editComponentSelected && <EditComponent sm={() => selectedMenu(MENU_CREATE_COMPONENT)} />}

        </Grid>

      </Grid>

    </Sheet>
  );
}
