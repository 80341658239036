import { CssVarsProvider, extendTheme, StyledEngineProvider } from '@mui/joy/styles';
import './App.css';
import Home from './pages/Home/Home.js';

const theme = extendTheme({
  components: {
    // JoySelect: {
    //   defaultProps: {
    //     indicator: '↕',
    //   },
    // },

    JoyInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === 'secondary' && {
            color: "theme.vars.palette.text.secondary",
            backgroundColor: theme.vars.palette.background.level1,
            "&.Joy-focused": {
              border: "2px solid #4EA5C5",
            },
          }),
        }),
      },
      defaultProps: {
        sx:{
          p:1,
          mb:4,
        }
      }
    },
    JoyButton: {
      defaultProps: {
        sx:{
          bgcolor:"#61DAFB",
          "&:hover":{
            bgcolor:"#4EA5C5",
            color: 'white',
          }
        }
      }
    }
  },
});

function App() {
  return (
    <div className="App">
        <header className="App-header">
          <StyledEngineProvider injectFirst>
            <CssVarsProvider theme={theme}>
              <Home />
            </CssVarsProvider>
          </StyledEngineProvider>
        </header>
    </div>
  );
}

export default App;
