export const verticalFlexStyle = { display:"flex", flexDirection:"column", paddingLeft: 10, paddingRight: 10, paddingTop: 3, paddingBottom: 3,maxHeight:"90vh"};
export const ListStyles = {
    '& > li > div':{p:1.5},
    '& > li:nth-child(odd)': {
        backgroundColor: '#F5F5F5',
        },
    '& > li:hover > div': {
            color: 'white',
            background: '#4EA5C5',
            },
    '& > li > div > svg': {
        width:20,
        paddingLeft:1,
        color: '#61DAFB'
    },
    '& > li > div > svg:hover': {
        color: '#FFF'
    }
        }