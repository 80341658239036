import axios from "axios"

const baseUrl = '/components'

export function createComponent(componentName, templateName, onSuccess, onError) {

    axios.post(baseUrl, {name: componentName, template: templateName})
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function getComponents(onSuccess, onError) {

    axios.get(baseUrl)
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function getComponentsExpanded(onSuccess, onError) {

    axios.get(baseUrl+'/expanded')
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function getComponent(componentName, onSuccess, onError) {

    axios.get(baseUrl+'/'+componentName)
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function deleteImage(imgSrc, onSuccess, onError) {

    axios.delete(imgSrc)
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function getImages(componentName, onSuccess, onError) {

    axios.get(baseUrl+'/'+componentName+'/images')
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

// export function exportToPdf(componentName, onSuccess, onError) {

//     axios.get(baseUrl+'/'+componentName+'/pdf')
//     .then(response => onSuccess(response.data))
//     .catch(error => onError(error.response.data))
    
// }

export function updateComponent(componentName, content, onSuccess, onError) {

    axios.put(baseUrl+'/'+componentName, {content: content})
    .then(response => onSuccess(response.data))
    .catch(error => onError(error.response.data))
    
}

export function deleteComponent(documentId, onSuccess, onError) {
    axios
      .delete(baseUrl + '/' + documentId)
      .then((response) => onSuccess(response.data))
      .catch((error) => onError(error.response.data));

}