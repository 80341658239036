import Keycloak from "keycloak-js";

// const env = process.env
// console.log(process.env.REACT_APP_KEYCLOAK_URL)

const keycloak = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

const keycloakInitOptions = {
    onLoad: 'login-required',
}

export {keycloak, keycloakInitOptions}