

function buildTableOfContents(editor) {
  const tableOfContents = editor.DomComponents.getWrapper().find('#table-of-contents')
  const pages = editor.DomComponents.getWrapper().find('.page')
  let content = ''

  pages.forEach((page) => {
    if(page.view.el.classList.contains('page-table-of-contents'))
      return;
    const header = page.find('h4')
    const subTitles = page.find('h5')
    // console.log(subTitles)
    if(header.length>0) {
      content += `<div><b>${header[0].view.el.innerText}<b>`
      
      if(subTitles.length>0) {
        content += '<div style="padding-left:20px;font-weight:normal">'
        subTitles.forEach((subTitle)=>{
            content += `${subTitle.view.el.innerText}<br>`
        })
        content += '</div>'
      }
      content += '</div>'
    }
  })

  console.log(content)

  tableOfContents[0].replaceWith(`<div id="table-of-contents" style="padding:10px">${content}</div>`)
}

export default function setupTOCBlock(editor) {

  editor.BlockManager.add('table-of-contents', {
    category: 'Components',
    label: 'Table Of Contents',
    attributes: { class: 'gjs-fonts gjs-f-b1' },
    content: '<div id="table-of-contents">Refresh this element to generate table of contents</div>'
  })


  editor.on('component:selected', (selectedComponent) => {
    if (selectedComponent.ccid == 'table-of-contents') {

      if(selectedComponent.closest('.page-table-of-contents')==0)
        selectedComponent.closest('.page').view.el.classList.add('page-table-of-contents')

      const defaultToolbar = selectedComponent.get('toolbar')
      const commandTableOfContents = () => buildTableOfContents(editor)

      const commandExists = defaultToolbar.some((item) => item.command.name === 'commandTableOfContents');
      if (!commandExists) {
        selectedComponent.set({
          toolbar: [...defaultToolbar, { attributes: { class: 'fa fa-refresh' }, command: commandTableOfContents }]
        });
      }


    }

  })

}