import { BUILDER_TYPE } from '../../../constants/project-types.const.mjs';
import { deleteImage as deleteProjectImage, getImages as getProjectImages } from '../../../services/project.service.mjs';
import { deleteImage as deleteTemplateImage, getImages as getTemplateImages } from '../../../services/template.service.mjs';
import { deleteImage as deleteComponentImage, getImages as getComponentImages } from '../../../services/component.service.mjs';


export default function assetManagerConfig(editor, projectName, builderType) {

    let getImagesService = null
    let deleteImageService = null

    switch (builderType) {
        case BUILDER_TYPE.PROJECT:
            getImagesService = getProjectImages
            deleteImageService = deleteProjectImage
            break
        case BUILDER_TYPE.TEMPLATE:
            getImagesService = getTemplateImages
            deleteImageService = deleteTemplateImage
            break
        case BUILDER_TYPE.COMPONENT:
            getImagesService = getComponentImages
            deleteImageService = deleteComponentImage
            break
    }

    editor.on('asset:open', () => {
        const assetManager = editor.AssetManager;
        if (assetManager.getAll().length == 0) {
            getImagesService(projectName, (resp) => {
                console.log(resp)
                resp.data.forEach((image) => {
                    assetManager.add(image)
                })
            }, (err) => {

            })

        }
    });

    editor.on('asset:remove', (options) => {
        console.log(options)
        const url = options.attributes.src
        deleteImageService(url, (resp) => {
            console.log(resp)
        }, (err) => {

        })
    })

    // editor.on('asset:upload:response', (response) => {
    //   // console.log(response)
    // })

    // editor.on('asset:close', () => {
    //   const selected = editor.getSelected();
    //   const url = selected.attributes.src.replace('thumbnails-','')
    //   console.log('-------------------', url)
    //   selected.addAttributes({ src: url });
    // })
}