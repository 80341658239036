export function filterStrings(data,keywords=""){
        return data.filter(((p) => {
            let subStrings = keywords.split(" ")
            
            for (let i = 0; i < subStrings.length; i++) {
                if (p.toLowerCase().includes(subStrings[i].toLowerCase()) || p == "") {
                    return true
                } else {
                  return false
                }
              }
            return p;
        }));
}