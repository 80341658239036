import { useKeycloak } from "@react-keycloak/web";
import { axiosAuthorizationToken } from "../config/axios.config.mjs";

const PrivateRoute = ({ children }) => {
   const { keycloak } = useKeycloak();

   const isLoggedIn = keycloak.authenticated;

   if (isLoggedIn) {
      axiosAuthorizationToken(keycloak.token)
      return children
   }

   return null;
};

export default PrivateRoute;