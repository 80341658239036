import { List, ListItem, ListItemButton, Sheet, Tooltip, Typography } from "@mui/joy";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchField } from "../../../components/Fields.js";
import { AddHeader } from "../../../components/Herders.js";
import { deleteTemplate, getTemplates } from "../../../services/template.service.mjs";
import { filterStrings } from "../../../services/utils.js";
import { ListStyles, verticalFlexStyle } from "../../../styles.js";


export default function EditTemplate({sm}) {

    const [templates, setTemplates] = useState([])
    const [keywords, setKeywords] = useState("")
    const navigate = useNavigate();
    const goToBuilder = React.useCallback((val) => navigate('/builder?template='+val), [navigate]);
    
    const afterDelete = (data) => {
        getTemplates((resp) => {
            console.log(resp.data)
            setTemplates(resp.data)
        }, (err) => { })
    }
    React.useEffect(() => {

        getTemplates((resp) => {
            console.log(resp.data)
            if(keywords && keywords != "")
                setTemplates(filterStrings(resp.data,keywords))
            else
                setTemplates(resp.data)
        }, (err) => { })

    }, [keywords]);

    return (
        <Box sx={verticalFlexStyle}>
            <AddHeader action={sm} label="Templates" />
            <SearchField set={setKeywords} value={keywords} />
            <Sheet
            variant=""
            sx={{
                overflow: 'auto',
                borderRadius: 'sm'}}
                >
            <List>
                <ListItem nested>
                    <List aria-labelledby="nested-list-demo-1" size='sm' sx={ListStyles}>
                        {templates.map((val, i)=> <ListItem key={i}>
                        <ListItemButton ><div style={{flexGrow:2}}>{val}</div>
                        <Tooltip title="Editar" variant="solid" placement="top">
                        <svg onClick={()=>goToBuilder(val)}  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.60489 12.9107L0.604836 12.9107C0.48375 12.7894 0.437089 12.6126 0.48297 12.4474L0.60489 12.9107ZM0.60489 12.9107C0.72617 13.0318 0.903019 13.0785 1.06818 13.0328L1.0682 13.0328L4.67302 12.0342C4.75216 12.0123 4.82424 11.9703 4.88239 11.9121L12.4753 4.3196L12.4753 4.31957C13.2416 3.55165 13.2416 2.30852 12.4753 1.54061L12.4753 1.54057L11.9758 1.04119C11.2083 0.273769 9.96405 0.273769 9.19656 1.04119L1.60353 8.63355C1.54539 8.69169 1.5034 8.76377 1.48148 8.8429L0.482981 12.4474L0.60489 12.9107ZM11.8027 3.64716L11.3867 4.0631L9.45309 2.12966L9.86926 1.71369L9.86927 1.71368C10.2652 1.31778 10.9072 1.31778 11.3031 1.71368L11.3031 1.71369L11.8027 2.21301C11.8027 2.21302 11.8027 2.21304 11.8027 2.21305C12.198 2.60947 12.198 3.25094 11.8027 3.64716ZM2.63695 8.94552L8.78054 2.80231L10.714 4.73558L4.57023 10.8788L2.63695 8.94552ZM1.62404 11.8918L2.19119 9.84477L3.67107 11.3247L1.62404 11.8918Z" fill="currentColor" stroke="currentColor" stroke-width="0.1"/></svg>
                        </Tooltip>
                        <Tooltip title="Remover" variant="solid" placement="top">
                            <svg onClick={()=>deleteTemplate(val,afterDelete,(d)=>console.log(d))} width="13" height="13" viewBox="0 0 13 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="M12.571 11.2589C13.0721 11.7638 13.1351 12.2868 12.7553 12.6881C12.353 13.1119 11.7912 13.0804 11.2699 12.5686C9.80243 11.1213 8.34398 9.66504 6.88103 8.21326C6.75968 8.09378 6.63159 7.98106 6.34619 7.71956C6.16641 7.9743 6.02708 8.24031 5.82483 8.4432C4.44279 9.83637 3.04726 11.2183 1.65398 12.6047C1.32813 12.9271 0.923629 13.1502 0.512386 12.8775C0.260696 12.7106 0.00226301 12.3499 1.57769e-05 12.0727C-0.00223145 11.7774 0.235976 11.4257 0.46744 11.1935C1.85398 9.7755 3.26974 8.38684 4.67425 6.9869C4.82482 6.83811 4.96415 6.68257 5.1664 6.47066C3.54839 4.87234 1.98207 3.33038 0.429237 1.77715C0.247211 1.59455 0.0292293 1.34206 0.0202404 1.11212C0.00675697 0.823566 0.107884 0.431313 0.310134 0.262238C0.514632 0.0931638 0.914638 0.0706203 1.20903 0.117961C1.42701 0.15403 1.62926 0.381717 1.80679 0.5553C3.23378 1.962 4.65178 3.37547 6.07428 4.78668C6.19563 4.90616 6.32372 5.01887 6.60687 5.28037C6.7889 5.02563 6.92598 4.75962 7.12823 4.55673C8.51028 3.1613 9.90805 1.78166 11.3013 0.397497C11.6249 0.0728741 12.0294 -0.150304 12.4429 0.12247C12.6946 0.28929 12.9508 0.649983 12.9553 0.927266C12.9553 1.22258 12.7171 1.57426 12.4879 1.80871C11.0991 3.22443 9.68333 4.61309 8.27881 6.01303C8.13049 6.16182 7.98892 6.31737 7.74397 6.57661C8.4451 7.23262 9.13276 7.85707 9.79794 8.50406C10.7305 9.41256 11.6497 10.3368 12.571 11.2589Z" fill="currentColor"/></svg>
                           </Tooltip>
                            </ListItemButton>
                            </ListItem>)}
                    </List>
                </ListItem>
            </List>
            </Sheet>
        </Box>
    );

}