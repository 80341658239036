import { Box, Typography } from "@mui/joy";
import { AddButton } from "./Buttons";

export const AddHeader = ({label = "Sem Titulo",action}) =><Box sx={{
    mb:2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}}>
<Typography level="h4">
    {label}
</Typography>
{action?<AddButton lable="Criar" action={action} />:null}
</Box>