import axios from "axios";
import { BUILDER_TYPE } from "../../../constants/project-types.const.mjs";
import { updateComponent } from "../../../services/component.service.mjs";
import { updateProject } from "../../../services/project.service.mjs";
import { updateTemplate } from "../../../services/template.service.mjs";


const buildMainMenu = (save, exportProject=null, exit) => {

  const el = document.getElementsByClassName('gjs-pn-main-menu-container')[0];
  const nav = document.createElement('nav')
  const ul = document.createElement('ul')
  const elSave = document.createElement('li')
  const elExit = document.createElement('li')
  nav.id = 'main-nav'
  nav.className = 'hide'
  elSave.innerHTML = 'Guardar'
  elExit.innerHTML = 'Sair'
  elSave.onclick = save
  elExit.onclick = exit
  ul.appendChild(elSave)
  // console.log(el);
  
  if(exportProject!=null) {
    const elExport = document.createElement('li')
    elExport.innerHTML = 'Exportar'
    elExport.onclick = exportProject
    ul.appendChild(elExport)
  }
  
  ul.appendChild(elExit)
  nav.appendChild(ul)
  el.appendChild(nav)
}

function removeButtons(panels) {
  panels.removeButton("options", "export-template")
  panels.removeButton("options", "fullscreen")
  panels.removeButton("views", "open-tm")
  panels.getButton('options', "sw-visibility").set('active', 0)
  // console.log(panels.getPanel('options'))
  // console.log(panels.getButton('options', "sw-visibility"))
}


export default function setupPanels(editor, projectName, builderType) {

  const panels = editor.Panels
  let updateContent = null
  let projectType = null

  switch (builderType) {
    case BUILDER_TYPE.PROJECT:
      updateContent = updateProject
      projectType = 'Relatório'
      break
    case BUILDER_TYPE.TEMPLATE:
      updateContent = updateTemplate
      projectType = 'Template'
      break
    case BUILDER_TYPE.COMPONENT:
      updateContent = updateComponent
      projectType = 'Componente'
      break
  }

  removeButtons(panels)

  panels.addPanel({
    id: 'main-menu-container',
    buttons: [
      {
        className: 'main-menu fa fa-bars',
        // command(editor) { alert('Hello World'); }
      },
      {
        className: 'project-type',
        label: projectType
      },
      {
        className: 'project-name',
        label: projectName
      }
    ]
  });

  const save = (callback = null) => {
    const html = editor.getHtml()
    const css = editor.getCss()
    const content = html.replace('<body>', '').replace('</body>', '') + '<style>' + css + '</style>'
    // console.log('save', css)

    updateContent(projectName, content, (resp) => {
      console.log(resp.message)
      if (callback) callback()
    }, (err) => {
      console.log(err.message)
    })
  }

  let exportProject=null
  if(builderType==BUILDER_TYPE.PROJECT) {
    exportProject = () => {
      save(() => {
        console.log('exportProject')
        const url = axios.defaults.baseURL + '/projects/' + projectName + '/pdf'
        window.open(url, '_blank', 'noopener,noreferrer');
      })
    }
  }

  const exit = () => {
    console.log('exit')
    window.location = '/'
  }

  buildMainMenu(save, exportProject, exit)
}