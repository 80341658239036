import { Box, Button, Option, Select, Input, Typography, FormLabel, FormControl } from "@mui/joy";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { verticalFlexStyle } from "../../../styles.js";
import { createComponent, getComponents } from "../../../services/component.service.mjs";


export default function CreateComponent() {

    const [componentName, setComponentName] = useState('')
    const [sampleComponentName, setSampleComponentName] = useState('')
    const [components, setComponents] = useState([])
    const navigate = useNavigate();
    const goToBuilder = React.useCallback((val) => navigate('/builder?component='+val), [navigate]);

    React.useEffect(() => {

        getComponents((resp) => {
            console.log(resp.data)
            setComponents(resp.data)
        }, (err) => { })

    }, []);

    const createNewComponent = () => {
        createComponent(componentName, sampleComponentName, (resp) => {
            console.log(resp)
            goToBuilder(componentName)
        }, (err) => {
            alert(err.message)
        })
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                ...verticalFlexStyle
            }}
            noValidate
            autoComplete="off"
        >
            <div>

                <Typography level="h4">
                    Criar componente
                </Typography>
            </div>
            <div>
                <FormControl>
                    <FormLabel>
                        Name
                    </FormLabel>
                    <Input
                        value={componentName}
                        color="secondary"
                        onChange={field => setComponentName(field.target.value)} />

                </FormControl>

            </div>
            <div style={{marginBottom:"2em"}}>
                <Select
                    label="Template"
                    value={sampleComponentName}
                >
                    <Option key={-1} value=''>Selecionar componente template</Option>
                    {components.map((val, i) => <Option onClick={()=>setSampleComponentName(val)} key={i} value={val}>{val}</Option>)}
                </Select>

            </div>
            <Button onClick={() => createNewComponent()}>Iniciar</Button>
        </Box>
    );


}