import { Box, Button, Option, Select, Input, Typography,FormControl, FormLabel } from "@mui/joy";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { verticalFlexStyle } from "../../../styles.js";
import { createTemplate, getTemplates } from "../../../services/template.service.mjs";


export default function CreateTemplate() {

    const [templateName, setTemplateName] = useState('')
    const [sampleTemplateName, setSampleTemplateName] = useState('')
    const [templates, setTemplates] = useState([])
    const navigate = useNavigate();
    const goToBuilder = React.useCallback((val) => navigate('/builder?template='+val), [navigate]);

    React.useEffect(() => {

        getTemplates((resp) => {
            console.log(resp.data)
            setTemplates(resp.data)
        }, (err) => { })

    }, []);

    const createNewTemplate = () => {
        createTemplate(templateName, sampleTemplateName, (resp) => {
            console.log(resp)
            goToBuilder(templateName)
        }, (err) => {
            alert(err.message)
        })
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                ...verticalFlexStyle
            }}
            noValidate
            autoComplete="off"
        >
            <div>

                <Typography level="h4">
                    Criar template
                </Typography>
            </div>
            <div>
                <FormControl>
                    <FormLabel>
                        Name
                    </FormLabel>
                    <Input
                        value={templateName}
                        color="secondary"
                        onChange={field => setTemplateName(field.target.value)} />

                </FormControl>

            </div>
            <div style={{marginBottom:"2em"}}>
                <Select
                    label="Template"
                    value={sampleTemplateName}
                    //onChange={val => setSampleTemplateName(val)}
                >
                    <Option key={-1} value=''>Selecionar template</Option>
                    {templates.map((val, i) => <Option onClick={()=>setSampleTemplateName(val)} key={i} value={val}>{val}</Option>)}
                </Select>

            </div>
            <Button onClick={() => createNewTemplate()}>Iniciar</Button>
        </Box>
    );


}