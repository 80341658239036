import { Box, Button, Option, Select, Input, Typography, FormControl, FormLabel } from "@mui/joy";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { verticalFlexStyle } from "../../../styles.js";
import { createProject } from "../../../services/project.service.mjs";
import { getTemplates } from "../../../services/template.service.mjs";


export default function CreateReport() {

    const [projectName, setProjectName] = useState('')
    const [templateName, setTemplateName] = useState('')
    const [templates, setTemplates] = useState([])
    const navigate = useNavigate();
    const goToBuilder = React.useCallback((val) => navigate('/builder?project='+val), [navigate]);

    React.useEffect(() => {

        getTemplates((resp) => {
            console.log(resp.data)
            setTemplates(resp.data)
        }, (err) => { })

    }, []);

    const createNewProject = () => {
        createProject(projectName, templateName, (resp) => {
            console.log(resp)
            goToBuilder(projectName)
        }, (err) => {
            alert(err.message)
        })
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                ...verticalFlexStyle
            }}
            noValidate
            autoComplete="off"
        >
            <div>

                <Typography level="h4">
                    Criar relatório
                </Typography>
            </div>
            <div>
                <FormControl>
                    <FormLabel>
                        Name
                    </FormLabel>
                    <Input
                        value={projectName}
                        color="secondary"
                        onChange={field => setProjectName(field.target.value)} />

                </FormControl>

            </div>
            <div style={{marginBottom:"2em"}}>
                <Select
                    label="Template"
                    value={templateName}
                    //</div>onChange={val => setTemplateName(val)}
                >
                    <Option key={-1} value=''>Selecionar template</Option>
                    {templates.map((val, i) => <Option onClick={() => setTemplateName(val)} key={i} value={val}>{val}</Option>)}
                </Select>

            </div>
            <Button onClick={() => createNewProject()}>Iniciar</Button>
        </Box>
    );


}