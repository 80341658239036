import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import logo from '../../logo.svg';

export default function Welcome() {

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>

                <img src={logo} className="App-logo" alt="logo" sx={{ width: 20 }} />
                <Typography sx={{
                    pb:1,
                    fontFamily: 'Roboto', fontSize: '32px', fontWeight: 700,
                    lineHeight: '44px', letterSpacing: '0em', textAlign: 'center',
                }} color="#218EAC">Bem vindo!</Typography>
                <Typography variant="h4"
                sx={{
                    fontFamily: 'Roboto', fontSize: '16px', fontWeight: 400,
                    lineHeight: '22.19px', textAlign: 'center',
                }}>
                    Selecione uma opção no menu para proceder
                </Typography>

            </div>
        </Box>
    )


}